import React from "react"

export default function PrivacyPolicy() {
  return (
    <div className="w-full flex flex-col justify-center items-center text-center font-helvetica text-black p-4">
      <div className="lg:w-3/4 w-full flex flex-col justify-center items-center">
        <h1 className="legal-heading">Privacy Policy</h1>
        <p className="legal-content">
          <span>
            We at VSN (“VSN”, “we”, “our” or “us”) are committed to protecting
            the personal information of our Clients (“Clients”, “users”, “you”
            or “yours”) and have strong information gathering and dissemination
            practices in place to protect the personal data or information
            shared by the users of the website (“Site”). This Privacy Policy
            tells you what type of personal information we collect, how we store
            and how we may use that personal information, whether your personal
            information is disclosed to anyone, and what steps we take to
            protect your personal information.
          </span>{" "}
          <br />
          <br />
          <br />
          <span>
            This Privacy Policy is published and shall be construed in
            accordance with the provisions of The Information Technology Act,
            2000, the Information Technology (Reasonable security practices and
            procedures and sensitive personal data or information) Rules, 2011
            and other applicable laws of India along with European Regulation
            (EU) 2016/679 of the European Parliament and of the Council of 27
            April 2016 on the protection of natural persons with regards to the
            processing of personal data and on the free movement of such data
            and repealing Directive 95/46/EC (“GDPR”) and applicable data
            protection laws.
          </span>{" "}
          <br />
        </p>
      </div>
      <div className="lg:w-3/4 w-full flex flex-col justify-start items-center">
        <div className="legal-section">
          <h1>1 . What Information We Collect</h1>
          <div>
            <span>
              1.1 When you use our services and submit information on our Site,
              we may require you to provide us with certain information which is
              essential for us to be able to identify you accurately. Such
              information includes but is not limited to:{" "}
            </span>{" "}
            <br />
            <span>
              a) your name, email address and phone number which you shall
              provide for the purpose of subscribing to our email notifications
              and/or newsletters and/or whitepapers and other documents
              available for download from our website (including your name and
              email address);{" "}
            </span>{" "}
            <br />
            <span>b) your professional and personal interests </span> <br />
            <span>
              c) information about your computer and about your visits to and
              use of this website (including your IP address, geographical
              location, browser type and version, operating system, referral
              source, length of visit, page views and website navigation paths){" "}
            </span>{" "}
            <br />
            <span>
              d) any other personal information that you choose to send to us;
            </span>{" "}
            <br />
            <span>
              1. 2 We do not collect sensitive information like religious
              beliefs, political opinions, racial data or ethnic origin of the
              user. Please note that by submitting sensitive information you are
              consenting to our collection of that information.
            </span>{" "}
            <br />
          </div>
        </div>
        <div className="legal-section">
          <h1>2. Using personal information</h1>
          <div>
            <span>
              2.1 Personal information submitted to us through our website will
              be used for the purposes specified in this policy or on the
              relevant pages of the website.
            </span>{" "}
            <br />
            <span>2.2 We may use your personal information to:</span> <br />
            <span>(a) administer our website and business;</span> <br />
            <span>(b) personalise our website for you;</span> <br />
            <span>
              (c) enable your use of the services available on our website;
            </span>{" "}
            <br />
            <span>
              (d) send statements, invoices and payment reminders to you, and
              collect payments from you;
            </span>{" "}
            <br />
            <span>
              (e) send you non-marketing commercial communications;
            </span>{" "}
            <br />
            <span>
              (f) send you email notifications that you have specifically
              requested;
            </span>{" "}
            <br />
            <span>
              (g) send you our email newsletter, if you have requested it (you
              can inform us at any time if you no longer require the
              newsletter);
            </span>{" "}
            <br />
            <span>
              (h) enable you to download information/white papers/documents you
              have requested;
            </span>{" "}
            <br />
            <span>
              (i) send you marketing communications relating to our business
              which we think may be of interest to you, by post or, where you
              have specifically agreed to this, by email or similar technology
              (you can inform us at any time if you no longer require marketing
              communications);
            </span>{" "}
            <br />
            <span>
              (j) deal with enquiries and complaints made by or about you
              relating to our website;
            </span>{" "}
            <br />
            <span>(k) keep our website secure and prevent fraud; and</span>{" "}
            <br />
            <span>
              (l) verify compliance with the terms and conditions governing the
              use of our website (including monitoring private messages sent
              through our website private messaging service).
            </span>{" "}
            <br />
            <span>
              2.3 We will not supply your personal information to any third
              party for the purpose of their or any other third party’s direct
              marketing.
            </span>{" "}
            <br />
          </div>
        </div>
        <div className="legal-section">
          <h1>3. Disclosing personal information</h1>
          <div>
            <span>
              3.1 We may disclose your personal information to any of our
              employees insofar as reasonably necessary for the purposes set out
              in this policy.
            </span>{" "}
            <br />
            <span>
              3.2 We may disclose your personal information to any member of our
              group of companies (this means our subsidiaries, our ultimate
              holding company and all its subsidiaries) insofar as reasonably
              necessary for the purposes set out in this policy.
            </span>{" "}
            <br />
            <span>3.3 We may disclose your personal information:</span> <br />
            <span>
              (a) to the extent that we are required to do so by law;
            </span>{" "}
            <br />
            <span>
              (b) in connection with any ongoing or prospective legal
              proceedings;
            </span>{" "}
            <br />
            <span>
              (c) in order to establish, exercise or defend our legal rights
              (including providing information to others for the purposes of
              fraud prevention and reducing credit risk);
            </span>{" "}
            <br />
            <span>
              (d) to any person who we reasonably believe may apply to a court
              or other competent authority for disclosure of that personal
              information where, in our reasonable opinion, such court or
              authority would be reasonably likely to order disclosure of that
              personal information.
            </span>{" "}
            <br />
            <span>
              3.4 Except as provided in this policy, we will not provide your
              personal information to third parties.
            </span>{" "}
            <br />
          </div>
        </div>
        <div className="legal-section">
          <h1>4. International data transfers</h1>
          <div>
            <span>
              4.1 Information that we collect may be stored and processed in and
              transferred between any of the countries in which we operate in
              order to enable us to use the information in accordance with this
              policy.
            </span>{" "}
            <br />
            <span>
              4.2 You expressly agree to the transfers of personal information
              described in this Section 5.
            </span>{" "}
            <br />
          </div>
        </div>
        <div className="legal-section">
          <h1>5. Security of personal information</h1>
          <div>
            <span>
              5.1 We will take reasonable technical and organisational
              precautions to prevent the loss, misuse or alteration of your
              personal information.
            </span>{" "}
            <br />
            <span>
              5.2 We will store all the personal information you provide on our
              secure (password- and firewall-protected) servers.
            </span>{" "}
            <br />
            <span>
              5.3 You acknowledge that the transmission of information over the
              internet is inherently insecure, and we cannot guarantee the
              security of data sent over the internet.
            </span>{" "}
            <br />
            <span>
              5.4 You are responsible for keeping the password you use for
              accessing our website confidential; we will not ask you for your
              password (except when you log in to our website).
            </span>{" "}
            <br />
          </div>
        </div>
        <div className="legal-section">
          <h1>6. Amendments</h1>
          <div>
            <span>
              6.1 We may update this policy from time to time by publishing a
              new version on our website.
            </span>{" "}
            <br />
            <span>
              6.2 You should check this page occasionally to ensure you are
              happy with any changes to this policy.
            </span>{" "}
            <br />
          </div>
        </div>
        <div className="legal-section">
          <h1>7. Your rights</h1>
          <div>
            <span>
              7.1 You may instruct us to provide you with any personal
              information we hold about you; provision of such information will
              be subject to:
            </span>{" "}
            <br />
            <span>
              (a) the supply of appropriate evidence of your identity (for this
              purpose, we will usually accept a photocopy of your passport
              certified by a solicitor or bank plus an original copy of a
              utility bill showing your current address).
            </span>{" "}
            <br />
            <span>
              7.2 We may withhold personal information that you request to the
              extent permitted by law.
            </span>{" "}
            <br />
            <span>
              7.3 You may instruct us at any time not to process your personal
              information for emailing you the updates.
            </span>{" "}
            <br />
            <span>
              7.4 In practice, you will usually either expressly agree in
              advance to our use of your personal information for marketing
              purposes, or we will provide you with an opportunity to opt out of
              the use of your personal information
            </span>{" "}
            <br />
            <span>
              7.5 To exercise your rights please send an email to
              helpdesk@VSNsharedservices.com with the subject Privacy Policy.
            </span>{" "}
            <br />
          </div>
        </div>
        <div className="legal-section">
          <h1>8. Third party websites</h1>
          <div>
            <span>
              8.1 Our website includes hyperlinks to, and details of, third
              party websites.
            </span>{" "}
            <br />
            <span>
              8.2 We have no control over, and are not responsible for, the
              privacy policies and practices of third parties.
            </span>{" "}
            <br />
          </div>
        </div>
        <div className="legal-section">
          <h1>9. Updating information</h1>
          <div>
            <span>
              9.1 Please let us know if the personal information that we hold
              about you needs to be corrected or updated.
            </span>{" "}
            <br />
          </div>
        </div>
        <div className="legal-section">
          <h1>10. Third Part Chat</h1>
          <div>
            <span>
              10.1 We use an online chat system provided by a third party
              software provider.
            </span>{" "}
            <br />
            <span>
              10.2 All data (active and archived) will be destroyed upon
              request, i.e. account termination.
            </span>{" "}
            <br />
          </div>
        </div>
      </div>
      <div className="lg:w-3/4 flex justify-center items-center">
        <p className="text-center text-xl">
          For any privacy related queries or concerns, you may contact VSN Data
          Protection Officer at helpdesk@VSNsharedservices.com with the Subject
          Privacy Data.
        </p>
      </div>
    </div>
  )
}
